import { ref, watch, Ref, unref, onUnmounted } from "vue";
import { DateTime } from "luxon";
import { MaybeRef } from "@/types";

export interface UseLiveTimeResult {
  time: Ref<DateTime>;
}

export default function useLiveTime(time: MaybeRef<DateTime | null> = null): UseLiveTimeResult {
  let startingMillis = Date.now();
  let startingTime = DateTime.now();
  const liveTime = ref(startingTime);

  function resetStartingTime() {
    startingMillis = Date.now();
    startingTime = unref(time) ?? DateTime.now();
    updateLiveTime();
  }

  function updateLiveTime() {
    const offsetMillis = Date.now() - startingMillis;
    liveTime.value = startingTime.plus(offsetMillis);
  }

  watch(() => unref(time), resetStartingTime, { immediate: true });

  const interval = setInterval(updateLiveTime, 1000);
  onUnmounted(() => clearInterval(interval));

  return {
    time: liveTime
  };
}

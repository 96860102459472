import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (parentAssetUuid: string, childAssetUuids: string[], linkType: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($parentAssetUuid: ID!, $childAssetUuids: [ID!]!, $linkType: AssetLinkType!) {
          assetAddChildren(parentAssetUuid: $parentAssetUuid, childAssetUuids: $childAssetUuids, linkType: $linkType)
        }
      `,
      variables: {
        parentAssetUuid,
        childAssetUuids,
        linkType
      }
    })
    .then(response => {
      return response.data.assetAddChildren;
    });
}
